export const DOC = 'doc';
export const DOCX = 'docx';
export const XLSX = 'xlsx';
export const XLS = 'xls';
export const PDF = 'pdf';
export const JPG = 'jpg';
export const JPEG = 'jpeg';
export const GIF = 'gif';
export const PNG = 'png';

export const BLOB_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const BLOB_PDF = 'application/pdf';
